import me from '../../images/moi.webp'

function About() {
  return (
    <article className="about">
      <h2 className='about_title'>QUI SUIS-JE ?</h2>
      <div className='about_me'>
        <img src={me} alt="me" className='meImg'/>
        <p className='about_me_text'>
          Je m'appelle Kevin Brunet, développeur front-end junior. <br />
          Ancien boucher, j'ai décidé de me reconvertir pour un métier qui me correspond mieux. <br />
          Mon ancien métier m'a appris à être à l'écoute des clients afin de mieux répondre à leurs besoins. <br />
          Ma formation avec Openclassrooms m'a permis d'apprendre en pratiquant avec des conditions similaire à des missions professionnelles. <br />
          Passionné d'informatique et de nouvelles technologies, ce choix de reconversion était plutôt naturel.
        </p>
      </div>
    </article>
  );
};

export default About;